import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    students: Core.ImageAttributes;
    classroom: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const { students, classroom } = props.data;

  return (
    <Layout title="So Long Silos">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection
          marginalia={
            <Core.Caption>
              <Core.LeadIn>
                by Brett McCabe
                <br />
                <br />
                ILLUSTRATION BY GIULIO BONASERA
              </Core.LeadIn>
            </Core.Caption>
          }
        >
          <Core.LargeText fontStyle="sans">
            An ambitious “reimagining” of SAIS’ flagship master’s degree program
            aims to open the door for interdisciplinary learning and
            collaboration.
          </Core.LargeText>
        </Core.ContentSection>

        <Core.ContentSection>
          <p>
            International student Shuyi Long, a second-year MA in China Studies
            student, is thinking about becoming a journalist. She chose the
            master&rsquo;s program at SAIS as a way to sharpen her understanding
            of global politics and international relations.
          </p>
          <p>
            Long says she appreciated the school’s size and location in
            Washington, D.C., and its alumni connections and resources. And she
            has particularly prized the program’s rigorous grounding in
            economics, languages, and regional studies — the sort of skill set a
            future journalist needs for covering governments and businesses in
            the coming decades.
          </p>
          <p>
            All that said, &ldquo;Most of the people that I know are from China
            studies or are doing work related to Asia,&rdquo; Long says of her
            first year at SAIS. &ldquo;I don’t know anyone from Middle East
            Studies or from European Studies.&rdquo;
          </p>
          <p>
            Addressing those silos in the student experience is central to an
            ambitious “reimagining” of SAIS’ flagship master&rsquo;s degree
            program. An administrative reorganization and new curriculum, it
            represents the first significant rethink of the core curriculum in
            50 years.
          </p>
          <p>
            This three-year effort grew out of the recognition from leadership
            and the faculty that the postwar global challenges that inspired
            Paul Nitze and Christian Herter to create a school for international
            studies have evolved — and so must the institution that is training
            tomorrow&rsquo;s global problem-solvers.
          </p>
          <p>
            &ldquo;The world is changing, and so are we,&rdquo; says Filipe
            Campante, vice dean for education and academic affairs at SAIS and a
            Johns Hopkins Bloomberg Distinguished Associate Professor of
            International Economics.
          </p>
          <p>
            Today’s issues require interdisciplinary responses, he notes. “If
            you think about climate change, that requires knowledge that
            wasn&rsquo;t built by a single discipline but bringing ideas
            together around a problem. That bringing together process was
            hindered [at SAIS], I think, by the departmental structure that
            siloed expertise apart,” he says.
          </p>
          <p>
            “The goal here, in both the curricular and organizational aspects,
            is to break down these silos and allow more integration and
            interdisciplinary thinking. We want all the expertise that we have
            at SAIS to interact and integrate.&rdquo;
          </p>

          <Core.FullWidthImage image={students} alt="Students" />
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <Core.Blockquote
              color="beige"
              author="Filipe Campante, vice dean for education and academic affairs"
            >
              The goal here, in both the curricular and organizational aspects,
              is to break down these silos and allow more integration and
              interdisciplinary thinking. We want all the expertise that we have
              at SAIS to interact and integrate.
            </Core.Blockquote>
          }
        >
          <p>
            Under the new curriculum, incoming master’s degree students will
            pick a functional focus (Security, Strategy and Statecraft;
            Development, Climate, and Sustainability; International Economics
            and Finance; States, Markets, and Institutions; or Technology and
            Culture) and regional focus area (Africa, Asia, China, Europe and
            Eurasia, Latin America, the Middle East, or the United States). All
            students will receive training in five core competencies — data
            analytics, economics, geopolitics, leadership and ethics, and
            research methodology — as well as greater access to internships and
            co-curricular activities that are important elements of career
            building.
          </p>
          <p>
            Students will also be taught by a single, unified SAIS faculty that
            isn’t dispersed into 19 different specialty departments. Some
            support staff positions were downsized in this streamlining — the
            reimagining impetus was driven by both financial and academic
            concerns — but it also centralizes SAIS’ individual program
            resources to provide all students with better access to experiential
            learning opportunities.
          </p>
          <p>
            By centralizing academic trips and professional development
            opportunities under one office, the reorganization “encourages
            project-based learning and makes sure all students receive these
            opportunities, not just students in specific concentrations,&ldquo;
            says Julie Micek, assistant dean for academic affairs.
          </p>

          <Core.FullWidthImage image={classroom} alt="Classroom" />

          <p>
            Curriculum changes were informed by three years of internal and
            external reviews of SAIS, including faculty interviews conducted by
            Janice Stein — a professor of conflict management (and former dean)
            at the University of Toronto Munk School of Global Affairs and
            Public Policy — and student feedback collected over the years. The
            findings were presented to the faculty at a retreat earlier this
            year, and Dean Eliot Cohen put together a committee, chaired by
            Campante, which came up with an outline of proposed changes. Cohen
            approved those changes in May.
          </p>
          <p>
            SAIS’ administrative restructuring happened over the summer, and
            students matriculating next academic year (2021–22) will start with
            the new curriculum. While the school’s eventual move to its new home
            at 555 Pennsylvania Ave. NW didn’t specifically inform the changes,
            Micek notes that it is opportune that SAIS is getting its proverbial
            house in order before relocating.
          </p>
          <p>
            “When we move, multiple JHU divisions will be in the same building,
            which allows more opportunities for collaboration. We will have our
            curriculum set and our faculty organized before we make that move,”
            she says.
          </p>
          <p>
            The ultimate goal is to give students more flexibility in building
            their degree programs while ensuring they all receive the broad
            training in SAIS’ foundational expertise. Such a grounding will be
            key for SAIS graduates as they embark on future careers, says
            Campante.
          </p>
          <p>
            He points out that the world is currently enduring a moment that
            makes such problem-based thinking a necessity.
          </p>
          <p>
            The COVID-19 pandemic “illustrates how big, international problems
            can emerge,” he says. “Is it an economic problem? Is it a
            geopolitical problem? Is it an environmental problem? Is it a public
            health problem? The answer is yes, it’s all of those things — and
            more.”
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    students: file(
      relativePath: { regex: $directory }
      name: { eq: "students" }
    ) {
      ...FullWidthImage
    }
    classroom: file(
      relativePath: { regex: $directory }
      name: { eq: "classroom" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default FeatureContent;
