import './Heading.scss';

import React from 'react';
import MastheadImage from './images/masthead.gif';

import * as Core from 'components/core';

const Heading: React.FC = () => {
  return (
    <Core.Masthead color="dark" className="SilosHeading">
      <div className="SilosHeading-image">
        <img src={MastheadImage} className="SilosHeading-image-image" />
      </div>
      <Core.ContentWrapper className="SilosHeading-wrapper">
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <h1 className="SilosHeading-title">So Long, Silos</h1>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default Heading;
